import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, Dropdown } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ username, onToggleSideMenu, onSignOut }) => (
  <Menu fixed="top" inverted>
    <Menu.Item className="button--hamburger">
      <Button icon="content" onClick={onToggleSideMenu} />
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/chat-room">Chat</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/bingo">Bingo</NavLink>
    </Menu.Item>
    <Menu.Menu position="right">
      <Dropdown item text={username}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onSignOut}>Sign out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
);

Navbar.propTypes = {
  username: PropTypes.string,
  onToggleSideMenu: PropTypes.func,
  onSignOut: PropTypes.func
};

Navbar.defaultProps = {
  username: null,
  onToggleSideMenu: null,
  onSignOut: null
};

export default Navbar;
