import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Header, Image, Message, Segment, Checkbox } from 'semantic-ui-react';
import { authActions } from 'actions';
import './Login.css';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const dispatch = useDispatch();

  const sendMessage = async () => {
    const body = new FormData();
    body.set('username', username.trim());
    body.set('password', password);
    body.set('remember-me', remember);

    setLoading(true);
    try {
      await dispatch(authActions.login(body));
      setLoading(false);
    } catch (error) {
      setLoginError(true);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (username && password) {
      sendMessage();
    } else {
      if (!username) {
        setInvalidUsername(true);
      }
      if (!password) {
        setInvalidPassword(true);
      }
    }
  };
  const handleChangeUsername = (_e, { value }) => {
    setUsername(value);
    setInvalidUsername(false);
  };
  const handleChangePassword = (_e, { value }) => {
    setPassword(value);
    setInvalidPassword(false);
  };
  const handleChangeRemember = (_e, { checked }) => setRemember(checked);

  return (
    <div className="login-form">
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            <Image src="/assets/babble-logo1-turq.png" />
          </Header>
          <Form size="large" onSubmit={handleSubmit} error={loginError}>
            <Segment stacked>
              <div className="login-form__message">Sign in to start chatting your brains out</div>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={username}
                name="username"
                onChange={handleChangeUsername}
                error={invalidUsername && 'Email is invalid'}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                value={password}
                onChange={handleChangePassword}
                error={invalidPassword && 'Password is invalid'}
              />
              <Form.Field>
                <Checkbox
                  label="Remember Me"
                  onChange={handleChangeRemember}
                  name="remember"
                  checked={remember}
                />
              </Form.Field>
              <Message error>There were some errors in your submission</Message>
              <Button
                loading={loading}
                disabled={loading}
                color="teal"
                fluid
                size="large"
                content="Login"
              />
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LoginForm;
