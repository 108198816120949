import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const TwoLinesWin = ({ bingoRoomName, bingoRoomType, totalJackpotAmount, winners }) => {
  const winnersList = Object.keys(winners);
  const winnersWithAmounts = winnersList.reduce(
    (acc, winner) => `${acc}${winner}, £ ${parseFloat(winners[winner]).toFixed(2)} | `,
    ''
  );

  return (
    <div>
      Niice!
      <b>
        {winnersList.length} player(s) just won Two Lines Jackpot (£
        {parseFloat(totalJackpotAmount).toFixed(2)})
      </b>
      on <BingoRoom id={bingoRoomName} type={bingoRoomType} />. The winners are:{' '}
      {winnersWithAmounts}
    </div>
  );
};

TwoLinesWin.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  totalJackpotAmount: PropTypes.number,
  winners: PropTypes.object
};

TwoLinesWin.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  winners: {},
  totalJackpotAmount: 0.0
};

export default TwoLinesWin;
