import React, { useState } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import createPersistedState from 'use-persisted-state';
import Notification from '../Notification';
import NewReply from './NewReply';

const useQuickReplyState = createPersistedState('quickReply');

const categories = {
  greetings: {
    id: 'greetings',
    text: 'Greetings',
    messages: [
      {
        id: 1,
        text: 'Hello, how can i help you today?'
      },
      {
        id: 2,
        text: 'I am really sorry to hear that. Is there anything we can do?'
      }
    ]
  },
  ending: {
    id: 'ending',
    text: 'Ending',
    messages: [
      {
        id: 1,
        text: 'Hello, how can i help you today?'
      },
      {
        id: 2,
        text: 'I am really sorry to hear that. Is there anything we can do?'
      }
    ]
  },
  further: {
    id: 'further',
    text: 'Further',
    messages: [
      {
        id: 1,
        text: 'Hello, how can i help you today?'
      },
      {
        id: 2,
        text: 'I am really sorry to hear that. Is there anything we can do?'
      }
    ]
  },
  bingo: {
    id: 'bingo',
    text: 'Bingo cheers',
    messages: [
      {
        id: 'bingo-1',
        text: 'One to go'
      },
      {
        id: 'bingo-2',
        text: 'Two to go'
      },
      {
        id: 'bingo-3',
        text: 'Well done'
      }
    ]
  }
};

const PredefinedMessages = ({ onSelectMessage }) => {
  const [category, setCategory] = useState(null);
  const [predefinedMessages, setPredefinedMessagesList] = useQuickReplyState(categories);

  const handleSetCategory = (categoryId) => setCategory(categoryId);

  const handleRemoveMessage = (text) => {
    setPredefinedMessagesList((list) => ({
      ...list,
      [category]: {
        messages: list[category].messages.filter((msg) => msg.text !== text)
      }
    }));
  };

  const handleAddNewCategory = (categ) => {
    setPredefinedMessagesList((list) => ({
      ...list,
      [categ]: {
        id: categ,
        text: categ,
        messages: []
      }
    }));
  };

  const handleAddNewReply = (reply) => {
    setPredefinedMessagesList((list) => ({
      ...list,
      [category]: {
        ...list[category],
        messages: [
          ...list[category].messages,
          {
            id: reply,
            text: reply
          }
        ]
      }
    }));
  };

  if (category) {
    return (
      <>
        <Popup.Header>{category}</Popup.Header>
        <Popup.Content>
          <Menu vertical>
            {predefinedMessages[category].messages.map((message) => (
              <Menu.Item
                as={Notification}
                key={message.id}
                text={message.text}
                timeout={message.timeout}
                onSelect={onSelectMessage}
                onRemove={handleRemoveMessage}
              >
                {message.text}
              </Menu.Item>
            ))}
            <Menu.Item>
              <NewReply placeholder="Add new reply" onAdd={handleAddNewReply} />
            </Menu.Item>
          </Menu>
        </Popup.Content>
      </>
    );
  }

  return (
    <>
      <Popup.Header>Quick replies</Popup.Header>
      <Popup.Content>
        <Menu vertical>
          <>
            {Object.keys(predefinedMessages).map((categoryId) => (
              <Menu.Item onClick={() => handleSetCategory(predefinedMessages[categoryId].id)}>
                {predefinedMessages[categoryId].text}
              </Menu.Item>
            ))}
            <Menu.Item>
              <NewReply placeholder="Add new folder" onAdd={handleAddNewCategory} />
            </Menu.Item>
          </>
        </Menu>
      </Popup.Content>
    </>
  );
};

export default PredefinedMessages;
