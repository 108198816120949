import Api from 'services/Api';

const getUser = (userRef) => ({
  type: 'GET_USER',
  payload: Api.chat.user(userRef),
  meta: { id: userRef }
});

export const usersActions = {
  getUser
};
