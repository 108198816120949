import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { sortBy } from 'lodash';
import { Card, List, Menu, Input, Loader } from 'semantic-ui-react';
import { chatActions } from 'actions';
import User from '../User';
import SortDropdown from '../SortDropdown';
import './Users.css';

const Users = ({ roomRef, users, usersRefs, className, currentUserUsername, isPrivate }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState('createdAt');
  const [order, setOrder] = useState('desc');

  useEffect(() => {
    if (!isPrivate) {
      dispatch(chatActions.getUsersForRoom(roomRef));
    }
  }, [dispatch, isPrivate, roomRef]);

  const handleChangeSearch = (e, { value }) => setSearch(value);

  const handleChangeSort = (e, { value }) => setSort(value);

  const handleChangeOrder = (e, { value }) => setOrder(value);

  const usersArray =
    usersRefs?.length &&
    sortBy(
      usersRefs.map((username) => ({
        username,
        ...users[username]
      })),
      (user) => !user.moderator
    )
      .filter((user) => {
        const searchInput = search && search.toLowerCase();
        const username = user.username.toLowerCase();
        const userRef = user.userRef.toLowerCase();
        const searchResults = username.includes(searchInput) || userRef.includes(searchInput);
        return search ? searchResults : true;
      })
      .sort((a, b) => {
        if (sort === 'createdAt') {
          return order === 'asc'
            ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        } else if (sort === 'isModerator') {
          return a.isModerator || b.isModerator ? -1 : 1;
        } else if (sort === 'isVIP') {
          return a.isVIP || b.isVIP ? -1 : 1;
        } else if (sort === 'username') {
          return order === 'asc'
            ? a.username.localeCompare(b.username)
            : b.username.localeCompare(a.username);
        } else {
          return 0;
        }
      });

  return (
    <Card className={className}>
      <List divided selection>
        {!isPrivate && (
          <Menu.Item>
            <Input icon="search" placeholder="Search users..." onChange={handleChangeSearch} />
            <SortDropdown
              onChange={handleChangeSort}
              value={sort}
              onChangeOrder={handleChangeOrder}
              order={order}
            />
          </Menu.Item>
        )}
        {usersArray ? (
          usersArray.map((user) => (
            <List.Item key={user.username} className="user">
              <User
                createdAt={user.createdAt}
                userRef={user.userRef}
                username={user.username}
                isModerator={user.isModerator}
                isVIP={user.isVIP}
                sessions={user.sessions}
                roomRef={roomRef}
                currentUserUsername={currentUserUsername}
              />
            </List.Item>
          ))
        ) : (
          <Loader size="small" active inline="centered">
            Loading users...
          </Loader>
        )}
      </List>
    </Card>
  );
};

const mapStateToProps = (state, { roomRef }) => ({
  users: state.Chat.entities[roomRef].users,
  usersRefs: state.Chat.entities[roomRef].userRefs,
  currentUserUsername: state.User.username
});

export default connect(mapStateToProps)(Users);
