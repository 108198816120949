import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BingoRoom from './BingoRoom';

const StartGame = ({
  bingoRoomName,
  bingoRoomType,
  approxEndDate,
  pattern,
  playersCount,
  ticketsCount
}) => {
  const endDate = moment(approxEndDate).format('h:mm:ss a');

  return (
    <div>
      Here we GO! A game just started on
      <BingoRoom id={bingoRoomName} type={bingoRoomType} />
      with <b>{playersCount} players</b> ({ticketsCount} purchased in total). Pattern used:
      {pattern}.{approxEndDate && `Game is scheduled to end at ${endDate} at the latest`}
    </div>
  );
};

StartGame.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  approxEndDate: PropTypes.string,
  pattern: PropTypes.string,
  playersCount: PropTypes.number,
  ticketsCount: PropTypes.number
};

StartGame.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  ticketsCount: 0,
  playersCount: 0,
  approxEndDate: null,
  pattern: ''
};

export default StartGame;
