import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingOverlay = () => (
  <Dimmer active inverted>
    <Loader size="large" />
  </Dimmer>
);

export default LoadingOverlay;
