import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

const NewReply = ({ placeholder, onAdd }) => {
  const [inputValue, setInputValue] = useState(null);
  const handleOnChange = (ev, { value }) => {
    setInputValue(value);
  };
  const handleOnSubmit = () => {
    if (onAdd) {
      setInputValue('');
      onAdd(inputValue);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <Input
        fluid
        placeholder={placeholder}
        value={inputValue}
        onChange={handleOnChange}
        action={{ type: 'submit', icon: 'add' }}
      />
    </Form>
  );
};

export default NewReply;
