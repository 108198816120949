import Api from 'services/Api';

const login = (body) => ({
  type: 'LOGIN',
  payload: Api.auth.login(body)
});

const resetSession = () => ({
  type: 'LOGOUT',
  payload: Api.auth.logout()
});

export const authActions = {
  login,
  resetSession
};
