import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash';
import BingoRoom from './BingoRoom';

const FullHouseWin = ({ bingoRoomName, bingoRoomType, totalJackpotAmount, winners }) => {
  const winnersList = Object.keys(winners);

  const winnersListWithAmounts =
    winnersList.length > 0 &&
    winnersList.reduce((acc, winner) => `${acc}${winner}, £ ${winners[winner].toFixed(2)} | `, '');

  return (
    <div>
      Niiice!
      <b>
        {winnersList.length} player(s) just won Full House Jackpot (£
        {round(parseFloat(totalJackpotAmount), 2)})
      </b>
      on
      <BingoRoom id={bingoRoomName} type={bingoRoomType} />. CALL someone! .{' '}
      {winnersListWithAmounts &&
        `The winners are:
      ${winnersListWithAmounts}`}
    </div>
  );
};

FullHouseWin.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  totalJackpotAmount: PropTypes.number,
  winners: PropTypes.object
};

FullHouseWin.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  totalJackpotAmount: 0,
  winners: {}
};

export default FullHouseWin;
