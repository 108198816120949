export default function authMiddleware() {
  return ({ dispatch }) => (next) => (action) => {
    const reset = () => setTimeout(() => dispatch({ type: 'Root/RESET' }), 0);

    if (action.type === 'LOGOUT_FULFILLED') {
      reset();
    }
    return next(action);
  };
}
