import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectEventsAllIds } from 'selectors/bingo';

const BingoSideMenu = () => {
  const eventsByCategories = useSelector((state) => selectEventsAllIds(state.Bingo));

  return eventsByCategories.map((eventCategory) => (
    <NavLink key={eventCategory} to={`/bingo/${eventCategory}`} className="item">
      {eventCategory}
    </NavLink>
  ));
};

export default BingoSideMenu;
