import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import './Chat.css';
import Users from '../Users';
import Conversation from '../Conversation';

const Chat = ({
  isPrivate,
  messagesByRoomRef,
  messages,
  roomRef,
  room,
  onSendMessage,
  connectFailed
}) => (
  <Grid>
    <Grid.Row className="chat__container">
      <Users className="chat__users" roomRef={roomRef} isPrivate={isPrivate} />
      <Conversation
        roomRef={roomRef}
        roomName={room.name}
        messages={messagesByRoomRef && messagesByRoomRef.map((messageRef) => messages[messageRef])}
        onSendMessage={onSendMessage}
        connectFailed={connectFailed}
      />
    </Grid.Row>
  </Grid>
);

const mapStateToProps = (state, { roomRef }) => ({
  messagesByRoomRef: state.Chat.entities[roomRef] && state.Chat.entities[roomRef].messages,
  messages: state.Chat.messages.entities,
  room: state.Chat.entities[roomRef],
  connectFailed: state.Socket.connectFailed
});

export default connect(mapStateToProps)(Chat);
