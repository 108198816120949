import React from 'react';
import { useParams } from 'react-router-dom';

import BingoEvents from '../BingoEvents';

const Bingo = () => {
  const { id } = useParams();

  return <BingoEvents eventType={id} />;
};

export default Bingo;
