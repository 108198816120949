import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const TicketPurchase = ({
  bingoRoomName,
  bingoRoomType,
  quickBuy,
  username,
  ticketPrice,
  ticketsCount
}) => {
  const ticketPriceStr = parseFloat(ticketPrice).toFixed(2);
  const buyMethod = quickBuy ? 'quick buy' : 'manual buy';

  if (ticketPrice > 0) {
    return (
      <div>
        Yuhuu! <b>{username}</b> just purchased ({buyMethod}) <b>{ticketsCount}</b> freaking
        ticket(s) on <BingoRoom id={bingoRoomName} type={bingoRoomType} />
        each worth £{ticketPriceStr}
      </div>
    );
  } else {
    return (
      <div>
        <b>{username}</b> just claimed <b>{ticketsCount}</b> FREE tickets on{' '}
        <BingoRoom id={bingoRoomName} type={bingoRoomType} />
      </div>
    );
  }
};

TicketPurchase.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  username: PropTypes.string,
  ticketPrice: PropTypes.number,
  ticketsCount: PropTypes.number,
  quickBuy: PropTypes.string
};

TicketPurchase.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  username: '[Unknown user]',
  ticketPrice: 0,
  ticketsCount: 0,
  quickBuy: 'manual buy'
};

export default TicketPurchase;
