export default {
  auth: {
    current: '/api/babble/session/current',
    login: 'POST /login',
    logout: '/logout'
  },
  chat: {
    rooms: '/api/babble/chat-rooms',
    privateMessage: 'POST /api/babble/admin/users/pm-user',
    closePrivate: 'POST /api/babble/admin/users/close-private',
    kick: 'POST /api/babble/admin/users/kick',
    ban: 'POST /api/babble/admin/users/ban',
    credit: 'POST /api/babble/admin/credit/cash',
    user: `/api/babble/users/:userRef`,
    users: '/api/babble/users?chatRoomRef={chatRoomRef}',
    messages:
      '/api/babble/message?chatRoomRef={chatRoomRef}&fromDate={fromDate}&toDate={toDate}&searchQuery={searchQuery}&username={username}'
  }
};
