/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Comment, Header } from 'semantic-ui-react';
import Message from './Message';

const Group = ({ title, messages, onTriggerFetch }) => {
  const [initialScroll, setInitialScroll] = useState(true);
  const [triger, setTriger] = useState(true);
  const msgContainer = document.getElementById('testpass');

  useEffect(() => {
    if (onTriggerFetch) {
      setTriger(true);
      if (messages.length === 0) {
        onTriggerFetch();
      }
    }
  }, [messages, onTriggerFetch]);

  useEffect(() => {
    const scrollBind = (e) => {
      if (e.target.scrollTop < 70) {
        msgContainer.scrollTo(0, 70);
      }
      if (e.target.scrollTop < 400 && triger) {
        onTriggerFetch();
        setTriger(false);
      }
    };
    if (msgContainer && onTriggerFetch) msgContainer.addEventListener('scroll', scrollBind);
    return () => {
      if (msgContainer && onTriggerFetch) msgContainer.removeEventListener('scroll', scrollBind);
    };
  }, [msgContainer, triger, onTriggerFetch]);

  useEffect(() => {
    if (msgContainer && initialScroll && messages.length > 0) {
      msgContainer.scrollTo(0, msgContainer.scrollHeight);
      setInitialScroll(false);
    } else if (messages.length === 0) {
      setInitialScroll(true);
    }
  }, [messages, msgContainer, initialScroll]);

  return (
    <Comment.Group id="testpass" ref={msgContainer}>
      {title && (
        <Header as="h3" dividing>
          {title}
        </Header>
      )}
      {messages.map((message) => (
        <Message
          key={message.id}
          author={message.createdBy || message.sentBy}
          time={message.createdAt || message.sentAt}
          content={message.value}
          sentByModerator={message.sentByModerator}
        />
      ))}
    </Comment.Group>
  );
};

Group.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.array
};

Group.defaultProps = {
  title: null,
  messages: []
};

export default Group;
