const addSubscription = (subscription) => ({
  type: 'ADD_SUBSCRIPTION',
  payload: subscription
});

const connectFailed = (subscription) => ({
  type: 'CONNECT_FAILED',
  payload: subscription
});

export const socketActions = {
  addSubscription,
  connectFailed
};
