import update from 'update-immutable';
import { selectUsersById } from 'selectors/users';

const INITIAL_STATE = {
  byId: {},
  allIds: []
};

const addUser = (state, id, user) =>
  update(
    state,
    selectUsersById(id)
      ? {
          byId: {
            [id]: { $merge: user }
          }
        }
      : {
          byId: {
            [id]: { $set: user }
          },
          allIds: { $push: [id] }
        }
  );

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_USER_FULFILLED': {
      const { data } = action.payload;
      const { id } = action.meta;

      return addUser(state, id, data);
    }
    case 'GET_USERS_FULFILLED': {
      const { data } = action.payload;

      return data.reduce((acc, user) => addUser(acc, user.userRef, user), state);
    }
    default:
      return state;
  }
};

export default userReducer;
