import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const TicketRefund = ({ bingoRoomName, bingoRoomType, username, ticketsCount, ticketPrice }) => {
  const ticketPriceStr = parseFloat(ticketPrice).toFixed(2);

  return (
    <div>
      Unfortunately! <b>{username}</b> was just refunded for <b>{ticketsCount}</b> ticket(s) on{' '}
      <BingoRoom id={bingoRoomName} type={bingoRoomType} />
      each worth £{ticketPriceStr}
    </div>
  );
};

TicketRefund.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  username: PropTypes.string,
  ticketPrice: PropTypes.number,
  ticketsCount: PropTypes.number
};

TicketRefund.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  username: '[Unknown user]',
  ticketPrice: 0,
  ticketsCount: 0
};

export default TicketRefund;
