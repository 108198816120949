import { omit, isEmpty } from 'lodash';
import update from 'update-immutable';

const INITIAL_STATE = {
  info: {
    visible: false
  }
};

const modalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      const extraData = omit(action.payload, 'name');

      return !isEmpty(extraData)
        ? update(state, {
            [action.payload.name]: {
              $merge: extraData,
              visible: { $set: true }
            }
          })
        : update(state, {
            [action.payload.name]: {
              visible: { $set: true }
            }
          });
    }
    case 'CLOSE_MODAL': {
      return update(state, {
        [action.payload.name]: {
          $set: { visible: false }
        }
      });
    }
    default:
      return state;
  }
};

export default modalsReducer;
