import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import './SortDropdown.css';

const SortDropdown = ({ value, onChange, order, onChangeOrder }) => (
  <Dropdown className="sortDropdown icon" icon="sort" pointing="right" floating button>
    <Dropdown.Menu>
      <Dropdown.Header content="Sort users by:" />
      <Dropdown.Divider />
      <Dropdown.Item
        value="createdAt"
        active={value === 'createdAt'}
        onClick={onChange}
        icon="hourglass outline"
        text="Joining date"
      />
      <Dropdown.Item
        value="isModerator"
        active={value === 'isModerator'}
        onClick={onChange}
        icon="bolt"
        text="Moderator"
      />
      <Dropdown.Item
        value="isVIP"
        active={value === 'isVIP'}
        onClick={onChange}
        icon="chess king"
        text="VIP"
      />
      <Dropdown.Item
        value="username"
        active={value === 'username'}
        onClick={onChange}
        icon="sort alphabet down"
        text="Username"
      />
      <Dropdown.Header content="Order:" />
      <Dropdown.Divider />
      <Dropdown.Item
        value="asc"
        active={order === 'asc'}
        onClick={onChangeOrder}
        icon="sort content ascending"
        text="Asceding"
      />
      <Dropdown.Item
        value="desc"
        active={order === 'desc'}
        onClick={onChangeOrder}
        icon="sort content descending"
        text="Descending"
      />
    </Dropdown.Menu>
  </Dropdown>
);

SortDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SortDropdown;
