import React from 'react';
import { Image, Menu, Segment, Sidebar } from 'semantic-ui-react';
import useDimensions from 'react-use-dimensions';
import './SideMenu.css';
import { Route, Switch } from 'react-router-dom';
import ChatSideMenu from './ChatSideMenu';
import BingoSideMenu from './BingoSideMenu';

const SideMenu = ({ isOpen, children }) => {
  const [ref, { width }] = useDimensions();

  const isMobile = width < 768;

  return (
    <div ref={ref}>
      <Sidebar.Pushable>
        <Sidebar as={Menu} vertical inverted visible={!isMobile || isOpen}>
          <Menu.Item as="a" header>
            <Image size="small" src="/assets/babble-logo1-white.png" />
          </Menu.Item>
          <Switch>
            <Route path="/chat-room/:roomRef" component={() => <ChatSideMenu />} />
            <Route path="/private-chat/:roomRef" component={() => <ChatSideMenu />} />
            <Route path="/chat-history" component={() => <ChatSideMenu />} />
            <Route path="/bingo" component={() => <BingoSideMenu />} />
            <Route path="/bingo/:id" component={() => <BingoSideMenu />} />
          </Switch>
        </Sidebar>
        <Sidebar.Pusher>
          <Segment basic>{children}</Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default SideMenu;
