import Api from 'services/Api';

const getCurrent = () => ({
  type: 'GET_CURRENT',
  payload: Api.auth.current()
});

export const userActions = {
  getCurrent
};
