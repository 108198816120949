const INITIAL_STATE = {
  authenticated: null,
  authenticating: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CURRENT_PENDING':
      return {
        ...state,
        authenticating: true
      };
    case 'GET_CURRENT_FULFILLED':
      return {
        ...state,
        authenticated: true,
        authenticating: false
      };
    case 'GET_CURRENT_REJECTED':
      return {
        ...state,
        authenticated: false,
        authenticating: false
      };
    case 'LOGIN_FULFILLED':
      return {
        ...state,
        authenticated: action.payload.data === null
      };
    case 'LOGOUT_FULFILLED':
      return {
        ...state,
        authenticated: false
      };
    default:
      return state;
  }
};

export default authReducer;
