import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash';
import BingoRoom from './BingoRoom';

const OneLineWin = ({ bingoRoomName, bingoRoomType, totalJackpotAmount, winners }) => {
  const winnersList = Object.keys(winners);
  const winnersListWithAmounts = winnersList.reduce(
    (acc, winner) => `${acc}${winner}, £ ${winners[winner].toFixed(2)} | `,
    ''
  );

  return (
    <div>
      Niiice!
      <b>
        {Object.keys(winners).length} player(s) just won One Line Jackpot (£
        {round(totalJackpotAmount, 2)})
      </b>
      on <BingoRoom id={bingoRoomName} type={bingoRoomType} />. The winners are:
      {winnersListWithAmounts}
    </div>
  );
};

OneLineWin.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  totalJackpotAmount: PropTypes.number,
  winners: PropTypes.object
};

OneLineWin.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  totalJackpotAmount: 0,
  winners: {}
};

export default OneLineWin;
