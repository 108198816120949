import React from 'react';
import PropTypes from 'prop-types';

const BingoRoom = ({ id, type }) => (
  <b>
    {id}({type})
  </b>
);

BingoRoom.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default BingoRoom;
