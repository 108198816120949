import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const CancelGame = ({ bingoRoomName, bingoRoomType }) => (
  <div>
    An in progress game <BingoRoom id={bingoRoomName} type={bingoRoomType} />
    was just CANCELLED due to an error. CALL someone!
  </div>
);

CancelGame.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string
};

CancelGame.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: ''
};

export default CancelGame;
