import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Message, Tab } from 'semantic-ui-react';
import { capitalize } from 'lodash';
import './BingoEvents.css';
import ResetGame from './ResetGame';
import RoomFavourite from './RoomFavourite';
import TicketPurchase from './TicketPurchase';
import TicketRefund from './TicketRefund';
import StartGame from './StartGame';
import CancelGame from './CancelGame';
import EndGame from './EndGame';
import ProgressiveWin from './ProgressiveWin';
import OneLineWin from './OneLineWin';
import TwoLinesWin from './TwoLinesWin';
import FullHouseWin from './FullHouseWin';
import Error from './Error';
import { selectAllEvents, selectAllEventsTypes, selectEventsAllIds } from '../../selectors/bingo';

const BingoRooms = ({ eventType, bingoEvents, eventsCategories, eventsByCategories }) => {
  const activeIndex = (eventType && eventsCategories.indexOf(eventType)) || 0;
  const history = useHistory();

  const handleTabChange = (ev, data) => {
    history.push(`/bingo/${eventsCategories[data.activeIndex]}`);
  };
  const panes = eventsCategories.map((key) => ({
    menuItem: key,
    render: () => (
      <Tab.Pane>
        {eventsByCategories[key].events.map((bingoEventId) => {
          const bingoEvent = bingoEvents[bingoEventId];
          const bingoRoomName = capitalize(bingoEvent.bingoRoomRef.split('-').join(' '));
          const bingoRoomType = bingoEvent.bingoRoomType === 'BINGO_75' ? '75' : '90';
          const eventKey = `${bingoEvent.event}-${bingoEvent.bingoRoomRef}-${bingoEvent.createdAt}`;
          if (bingoEvent.payload && bingoEvent.success) {
            switch (bingoEvent.event) {
              case 'CANCEL_GAME': {
                return (
                  <Message key={eventKey}>
                    <CancelGame bingoRoomName={bingoRoomName} bingoRoomType={bingoRoomType} />
                  </Message>
                );
              }
              case 'END_GAME': {
                return (
                  <Message key={eventKey}>
                    <EndGame bingoRoomName={bingoRoomName} bingoRoomType={bingoRoomType} />
                  </Message>
                );
              }
              case 'BINGO_GAME_JACKPOT_WINNER': {
                return (
                  <Message key={eventKey}>
                    <FullHouseWin
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      totalJackpotAmount={bingoEvent.payload.totalJackpotAmount}
                      winners={bingoEvent.payload?.winners}
                    />
                  </Message>
                );
              }
              case 'ONE_LINE_WINNER': {
                return (
                  <Message key={eventKey}>
                    <OneLineWin
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      totalJackpotAmount={bingoEvent.payload.totalJackpotAmount}
                      winners={bingoEvent.payload.winners}
                    />
                  </Message>
                );
              }
              case 'TWO_LINES_WINNER': {
                return (
                  <Message key={eventKey}>
                    <TwoLinesWin
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      totalJackpotAmount={bingoEvent.payload.totalJackpotAmount}
                      winners={bingoEvent.payload.winners}
                    />
                  </Message>
                );
              }
              case 'START_GAME': {
                return (
                  <Message key={eventKey}>
                    <StartGame
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      approxEndDate={bingoEvent.payload.approxEndDate}
                      pattern={bingoEvent.payload.patternUsed?.name}
                      playersCount={bingoEvent.payload.playersCount}
                      ticketsCount={bingoEvent.payload.ticketsCount}
                    />
                  </Message>
                );
              }
              case 'FAVOURITE_ROOM': {
                return (
                  <Message key={eventKey}>
                    <RoomFavourite
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      favourited
                      username={bingoEvent.payload.username}
                    />
                  </Message>
                );
              }
              case 'UNFAVOURITE_ROOM': {
                return (
                  <Message key={eventKey}>
                    <RoomFavourite
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      favourited={false}
                      username={bingoEvent.payload.username}
                    />
                  </Message>
                );
              }
              case 'TICKETS_QUICK_BUY': {
                return (
                  <Message key={eventKey}>
                    <TicketPurchase
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      quickBuy
                      username={bingoEvent.payload.username}
                      ticketPrice={bingoEvent.payload.ticketPrice}
                      ticketsCount={bingoEvent.payload.ticketsCount}
                    />
                  </Message>
                );
              }
              case 'TICKETS_MANUAL_BUY': {
                return (
                  <Message key={eventKey}>
                    <TicketPurchase
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      quickBuy={false}
                      username={bingoEvent.payload.username}
                      ticketPrice={bingoEvent.payload.ticketPrice}
                      ticketsCount={bingoEvent.payload.ticketsCount}
                    />
                  </Message>
                );
              }
              case 'TICKETS_REFUND': {
                return (
                  <Message key={eventKey}>
                    <TicketRefund
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      username={bingoEvent.payload.username}
                      ticketsCount={bingoEvent.payload.ticketsCount}
                      ticketPrice={bingoEvent.payload.ticketPrice}
                    />
                  </Message>
                );
              }
              case 'RESET_GAME': {
                return (
                  <Message key={eventKey}>
                    <ResetGame
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      reason={bingoEvent.payload.type}
                    />
                  </Message>
                );
              }
              case 'BINGO_PROGRESSIVE_WINNER': {
                return (
                  <Message key={eventKey}>
                    <ProgressiveWin
                      bingoRoomName={bingoRoomName}
                      bingoRoomType={bingoRoomType}
                      winners={bingoEvent.payload.winners}
                      totalJackpotAmount={bingoEvent.payload.totalJackpotAmount}
                    />
                  </Message>
                );
              }
              default: {
                return null;
              }
            }
          } else {
            return (
              <Message key={eventKey}>
                <Error
                  eventType={bingoEvent.event}
                  roomId={bingoRoomName}
                  roomType={bingoRoomType}
                />
              </Message>
            );
          }
        })}
      </Tab.Pane>
    )
  }));

  return (
    <div className="bingoEvents">
      <Tab activeIndex={activeIndex} panes={panes} onTabChange={handleTabChange} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bingoEvents: selectAllEvents(state.Bingo),
  eventsCategories: selectEventsAllIds(state.Bingo),
  eventsByCategories: selectAllEventsTypes(state.Bingo)
});

export default connect(mapStateToProps)(BingoRooms);
