import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const ProgressiveWin = ({ bingoRoomName, bingoRoomType, winners, totalJackpotAmount }) => {
  const winnersList = Object.keys(winners);
  const winnersListWithAmounts = winnersList.reduce(
    (acc, winner) => `${acc}${winner}, £ ${winners[winner].toFixed(2)} | `,
    ''
  );

  return (
    <div>
      WOWZAA!
      <b>
        {winnersList.length} player(s) just won PROGRESSIVE Jackpot (£
        {Number(totalJackpotAmount).toFixed(2)})
      </b>
      on <BingoRoom id={bingoRoomName} type={bingoRoomType} />. The winners are:
      {winnersListWithAmounts}
    </div>
  );
};

ProgressiveWin.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  winners: PropTypes.object,
  totalJackpotAmount: PropTypes.number
};

ProgressiveWin.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  totalJackpotAmount: 0,
  winners: {}
};

export default ProgressiveWin;
