const open = (name, extraProps) => ({
  type: 'OPEN_MODAL',
  payload: { name, ...extraProps }
});

const close = (name) => ({
  type: 'CLOSE_MODAL',
  payload: { name }
});

export const modalsActions = {
  open,
  close
};
