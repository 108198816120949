import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadingType } from 'selectors/loading';
import { Button, Form, Message, Modal, Select, Input, Label, Icon } from 'semantic-ui-react';
import Api from '../../services/Api';

const amountOptions = [
  { key: '1', value: '1', text: '£1' },
  { key: '2', value: '2', text: '£2' },
  { key: '5', value: '5', text: '£5' }
];

const defaultValue = (options) => options && options[0]?.value;

const Credit = ({ name, visible, userRef, username, closeModal }) => {
  const [amount, setAmount] = useState(defaultValue(amountOptions));
  const [reason, setReason] = useState();
  const [errorMessage, setError] = useState();

  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingType('CREDIT_USER'));

  const handleChangeReason = (ev, { value }) => {
    setReason(value);
    setError();
  };

  const handleChangeAmount = (ev, { value }) => {
    setAmount(value);
  };

  const handleSubmit = async () => {
    if (reason) {
      try {
        await dispatch({
          type: 'CREDIT_USER',
          payload: Api.chat.credit({
            amount,
            reason,
            userRef
          })
        });
        closeModal(name);
      } catch (error) {
        setError(error.toString());
      }
    } else {
      setError(`Please enter a reson to credit ${username}`);
    }
  };

  const handleCloseModal = () => {
    closeModal(name);
  };

  return (
    <Modal open={visible} size="tiny" onClose={handleCloseModal}>
      <Modal.Header>
        Credit{' '}
        <Label size="big">
          <Icon name="user circle" />
          {username}
        </Label>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                control={Select}
                placeholder="Select"
                label="Amount"
                options={amountOptions}
                onChange={handleChangeAmount}
                value={amount}
              />
              <Form.Field
                control={Input}
                label="Reason"
                onChange={handleChangeReason}
                value={reason}
                error={errorMessage}
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Close" onClick={handleCloseModal} />
        <Button positive content="Submit" onClick={handleSubmit} loading={loading} />
      </Modal.Actions>
    </Modal>
  );
};

export default Credit;
