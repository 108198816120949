import { useRef, useEffect } from 'react';

export const useAutoScroll = (length) => {
  const element = useRef(null);
  const prevLengthRef = useRef(length);
  const prevLength = prevLengthRef.current;

  useEffect(() => {
    prevLengthRef.current = length;
    if (prevLength < length && element.current) {
      element.current.scrollTop = element.current.scrollHeight;
    }
  }, [length, prevLength]);

  return element;
};
