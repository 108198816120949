import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { isNil } from 'lodash';
import Login from 'components/Login';
import Dashboard from 'components/Dashboard';
import LoadingOverlay from 'components/LoadingOverlay';

import { userActions } from 'actions';

class App extends Component {
  componentDidMount() {
    const { getCurrentUser } = this.props;
    getCurrentUser();
  }

  render() {
    const { authenticated, authenticating } = this.props;
    const isAuthenticating = isNil(authenticated) || authenticating;

    return (
      <Router>
        {isAuthenticating && <LoadingOverlay />}
        {authenticated ? (
          <Switch>
            <Route path="/(\?.*)?" component={Dashboard} />
          </Switch>
        ) : null}
        {!authenticated && !isAuthenticating ? (
          <Switch>
            <Route path="/login" component={Login} />
            <Redirect from="*" to="/login" />
          </Switch>
        ) : null}
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.Auth.authenticated,
  authenticating: state.Auth.authenticating
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => {
    dispatch(userActions.getCurrent());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
