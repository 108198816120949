import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import withReduxEnhancer from 'addon-redux/enhancer';
import reducers from 'reducers';
import authMiddleware from 'middlewares/auth';

const createMiddlewareEnhancer = () => {
  const middleware = [reduxThunk, authMiddleware(), promiseMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    // include other middlewares as needed, like the invariant and logger middlewares
    // middleware.push(invariant());
    // middleware.push(logger());
  }
  return applyMiddleware(...middleware);
};

const createEnhancer = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = [];
  enhancers.push(createMiddlewareEnhancer());
  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(withReduxEnhancer);
  }
  return composeEnhancers(...enhancers);
};

const configureStore = () => createStore(reducers, {}, createEnhancer());

export default configureStore;
