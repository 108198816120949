import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { modalsActions } from 'actions';
import BlockPlayer from './BlockPlayer';
import PlayerInfo from './PlayerInfo';
import CreditPlayer from './CreditPlayer';

class Modals extends PureComponent {
  render() {
    const { modals, closeModal } = this.props;
    const modalKeys = Object.keys(modals);

    return modalKeys.map((modalName) => {
      const props = modals[modalName];
      switch (modalName) {
        case 'block': {
          return (
            props.visible && (
              <BlockPlayer key={modalName} name={modalName} closeModal={closeModal} {...props} />
            )
          );
        }
        case 'credit': {
          return (
            props.visible && (
              <CreditPlayer key={modalName} name={modalName} closeModal={closeModal} {...props} />
            )
          );
        }
        case 'info': {
          return (
            props.visible && (
              <PlayerInfo key={modalName} name={modalName} closeModal={closeModal} {...props} />
            )
          );
        }
        default: {
          return null;
        }
      }
    });
  }
}

const mapStateToProps = (state) => ({
  modals: state.Modals
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => {
    dispatch(modalsActions.close(modal));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
