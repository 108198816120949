import Axios from 'axios';
import { toast } from 'react-toastify';
import mockApi from './mockApi';

const config = {
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  transformResponse: Axios.defaults.transformResponse.concat((data) => {
    if (data) {
      if (data.success && data.msg) {
        toast.success(data.msg);
      }
      if (!data.success && data.msg) {
        toast.error(data.msg);
      }

      if (data.data) {
        return data.data;
      } else {
        return data;
      }
    }
    return null;
  })
};

if (process.env.REACT_APP_MOCK_API === 'true') {
  mockApi(Axios);
}

export default Axios.create(config);
