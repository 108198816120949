import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const reasons = {
  DEFAULT: 'too many players',
  NO_PLAYERS: 'no players',
  NOT_ENOUGH_PLAYERS: 'not enough players'
};

const ResetGame = ({ bingoRoomName, bingoRoomType, reason }) => {
  const reasonText = reasons[reason];

  return (
    <div>
      A game was just reset on <BingoRoom id={bingoRoomName} type={bingoRoomType} />
      because there were {reasonText}.
    </div>
  );
};

ResetGame.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  reason: PropTypes.string
};

ResetGame.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  reason: 'DEFAULT'
};

export default ResetGame;
