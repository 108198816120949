import update from 'update-immutable';
import { normalize } from 'normalizr';
import { uniqueId } from 'lodash';
import { typeSchema } from '../services/Api/schema';
import { selectEventsAllIds, selectEventsByType } from '../selectors/bingo';

const INITIAL_STATE = {
  entities: {
    events: {},
    types: {}
  },
  result: []
};

// const maxSize = 100;

const bingoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FAVOURITE_ROOM':
    case 'UNFAVOURITE_ROOM':
    case 'TICKETS_QUICK_BUY':
    case 'TICKETS_MANUAL_BUY':
    case 'TICKETS_REFUND':
    case 'START_GAME':
    case 'RESET_GAME':
    case 'CANCEL_GAME':
    case 'END_GAME':
    case 'BINGO_PROGRESSIVE_WINNER':
    case 'BINGO_GAME_JACKPOT_WINNER':
    case 'ONE_LINE_WINNER':
    case 'TWO_LINES_WINNER': {
      const eventsAllIds = selectEventsAllIds(state);
      const eventPayload = {
        event: action.type,
        payload: action.payload
      };

      const eventCategoryExists = eventsAllIds.includes(eventPayload.event);

      const newEventsAllIds = eventCategoryExists
        ? eventsAllIds
        : [...eventsAllIds, eventPayload.event];

      const data = newEventsAllIds.map((eventType) => ({
        event: eventType,
        events:
          eventType === eventPayload.event
            ? [
                ...selectEventsByType(state, eventType),
                {
                  ...eventPayload.payload,
                  id: uniqueId('bingoEvent_')
                }
              ]
            : selectEventsByType(state, eventType)
      }));

      const normalizedData = normalize(data, [typeSchema]);
      return update(state, {
        entities: { $set: normalizedData.entities },
        result: { $set: normalizedData.result }
      });
    }
    default:
      return state;
  }
};

export default bingoReducer;
