import update from 'update-immutable';

const INITIAL_STATE = {
  email: null,
  id: null,
  ref: null,
  username: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CURRENT_FULFILLED': {
      const { data } = action.payload;
      return update(state, {
        $set: data
      });
    }
    default:
      return state;
  }
};

export default userReducer;
