import { groupBy, keyBy, forEach, difference, find } from 'lodash';
import update from 'update-immutable';
import { pushItemInArray } from 'utils/update';

const INITIAL_STATE = {
  messages: {
    entities: {},
    result: null
  },
  entities: {},
  result: null
};

const maxSize = 100;

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_ROOMS_FULFILLED': {
      const { data } = action.payload;
      return {
        ...state,
        entities: keyBy(data, 'ref'),
        result: data.map((room) => room.ref)
      };
    }
    case 'MESSAGE': {
      const roomRef = action.payload.chatRoomRef;
      const messageId = action.payload.ref;
      const roomMessages =
        state.entities && state.entities[roomRef] && state.entities[roomRef].messages;
      const messagesCount = roomMessages && roomMessages.length;
      const discardRefs =
        messagesCount &&
        messagesCount >= maxSize &&
        roomMessages.slice(0, messagesCount - maxSize + 1);
      const keepInRoom = discardRefs && roomMessages && difference(roomMessages, discardRefs);

      const updater = {
        entities: {
          [roomRef]: {
            messages: keepInRoom ? { $set: [...keepInRoom, messageId] } : { $push: [messageId] }
          }
        },
        result: pushItemInArray(roomRef, state.result),
        messages: {
          entities: {
            [messageId]: { $set: action.payload }
          },
          result: discardRefs
            ? {
                $splice: [
                  [state.messages.result.indexOf(discardRefs[0]), 1],
                  [messagesCount, 0, messageId]
                ]
              }
            : { $push: [messageId] }
        }
      };
      if (state.result && state.result.includes(roomRef)) delete updater.result;
      if (discardRefs) updater.messages.entities.$unset = discardRefs;

      return update(state, updater);
    }
    case 'GET_USERS_FULFILLED': {
      const { data } = action.payload;
      if (data.length) {
        const chatRoomRef = data[0].chatRoomRef;
        const usersByUsername = groupBy(data, (user) => user.username);
        const users = {};

        forEach(usersByUsername, (value, key) => {
          users[key] = {
            ...value[0],
            sessions: value.map((item) => ({
              userAgentInfo: item.userAgentInfo,
              sessionId: item.sessionId
            }))
          };
        });

        return update(state, {
          entities: {
            [chatRoomRef]: {
              users: {
                $set: users
              },
              userRefs: {
                $set: Object.keys(users)
              }
            }
          }
        });
      }

      return state;
    }
    case 'GET_MESSAGES_FULFILLED': {
      const {
        data: { contents: messages }
      } = action.payload;
      if (messages.length) {
        const roomRef = messages[0].chatRoomRef;
        const messagesRef = messages.map((message) => message.ref);
        const messagesByRef = keyBy(messages, (message) => message.ref);

        const updater = {
          entities: {
            [roomRef]: {
              messages: { $unshift: messagesRef }
            }
          },
          messages: {
            entities: {
              $merge: messagesByRef
            }
          }
        };

        return update(state, updater);
      }

      return state;
    }
    case 'SUBSCRIBE_USER': {
      const { subscriber } = action.payload;
      const { username, chatRoomRef } = subscriber;

      const users = (state.entities[chatRoomRef] && state.entities[chatRoomRef].users) || {};

      if ({}.hasOwnProperty.call(users, username)) {
        if (users[username].sessions && subscriber.sessionId) {
          if (!find(users[username].sessions, ['sessionId', subscriber.sessionId])) {
            users[username].sessions.push({
              userAgentInfo: subscriber.userAgentInfo,
              sessionId: subscriber.sessionId
            });
          }
        }
      } else {
        users[username] = subscriber;
        users[username].sessions = [
          {
            userAgentInfo: subscriber.userAgentInfo,
            sessionId: subscriber.sessionId
          }
        ];
      }

      return update(state, {
        entities: {
          [chatRoomRef]: {
            users: {
              $set: users
            },
            userRefs: {
              $set: Object.keys(users)
            }
          }
        }
      });
    }
    case 'UNSUBSCRIBE_USER': {
      const { subscriber } = action.payload;
      const { chatRoomRef, username } = subscriber;

      const users = (state.entities[chatRoomRef] && state.entities[chatRoomRef].users) || {};
      const userIndex =
        (state.entities[chatRoomRef] &&
          state.entities[chatRoomRef].userRefs &&
          state.entities[chatRoomRef].userRefs.indexOf(username)) ||
        0;

      if ({}.hasOwnProperty.call(users, username)) {
        const remainingSessions = users[username].sessions.filter(
          (session) => session.sessionId !== subscriber.sessionId
        );
        if (remainingSessions.length) {
          return update(state, {
            entities: {
              [chatRoomRef]: {
                users: {
                  [username]: {
                    sessions: {
                      $set: remainingSessions
                    }
                  }
                }
              }
            }
          });
        }
      }

      return update(state, {
        entities: {
          [chatRoomRef]: {
            users: {
              $unset: username
            },
            userRefs: {
              $splice: [[userIndex, 1]]
            }
          }
        }
      });
    }
    case 'START_PRIVATE_ROOM': {
      const { user, createdBy, createdAt } = action.payload;

      return update(state, {
        entities: {
          [user.username]: {
            $set: {
              name: user.username,
              ref: user.userRef,
              createdAt: createdAt,
              createdBy: createdBy,
              private: true,
              users: {
                [user.username]: {
                  username: user.username,
                  userRef: user.userRef,
                  createdAt: user.createdAt,
                  sessions: user.sessions
                },
                [createdBy]: {
                  username: createdBy,
                  userRef: createdBy,
                  isModerator: true
                }
              },
              userRefs: [createdBy, user.username]
            }
          }
        },
        result: pushItemInArray(user.username, state.result)
      });
    }
    case 'CLOSE_PRIVATE_ROOM': {
      const userRef = action.payload;
      const index = state.result.indexOf(userRef);

      return update(state, {
        entities: {
          $unset: userRef
        },
        result: {
          $splice: [[index, 1]]
        }
      });
    }
    default:
      return state;
  }
};

export default chatReducer;
