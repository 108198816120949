import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { ExportToCsv } from 'export-to-csv';
import {
  Button,
  Comment,
  Card,
  Form,
  Loader,
  Header,
  Input,
  Dropdown,
  Popup
} from 'semantic-ui-react';
import { format, subYears } from 'date-fns';
import Api from '../../services/Api';
import './ChatHistory.css';
import 'react-datepicker/dist/react-datepicker.css';
import Message from '../Message';

const baseMessagesState = {
  messages: [],
  currentPage: 0,
  totalPages: 1,
  loading: false,
  noMoreMessages: false
};

class Chat extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      openAdvanced: false,
      chatRoomRef: props.roomsRefs[0],
      messages: [],
      searchQuery: null,
      username: null,
      currentPage: 0,
      totalPages: 1,
      loading: false,
      noMoreMessages: false,
      searchQueryValue: null,
      usernameQueryValue: null,
      totalMessages: null,
      preparingExportData: false,
      exportRangeError: null
    };
  }

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
      ...baseMessagesState
    });
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
      ...baseMessagesState
    });
  };

  handleSelectRoom = (e, room) => {
    this.setState({
      chatRoomRef: room.value,
      ...baseMessagesState
    });
  };

  handleChangeSearch = (e, input) => {
    this.setState({
      searchQueryValue: input.value
    });
  };

  handleChangeUser = (e, input) => {
    this.setState({
      usernameQueryValue: input.value
    });
  };

  handleApplyExtraFilters = () => {
    this.setState((s) => ({
      ...baseMessagesState,
      username: s.usernameQueryValue,
      searchQuery: s.searchQueryValue
    }));
  };

  handleLoadMessages = () => {
    const {
      messages,
      startDate,
      endDate,
      chatRoomRef,
      searchQuery,
      username,
      currentPage,
      totalPages
    } = this.state;
    if (chatRoomRef && currentPage < totalPages) {
      const curentDate = new Date(Date.now());
      const page = curentDate;
      const sort = 'lastModifiedAt,desc';
      const fromDate = (startDate || subYears(curentDate, 4)).toISOString();
      const toDate = (endDate || curentDate).toISOString();

      this.setState({ loading: true });
      Api.chat
        .messages({ chatRoomRef, fromDate, toDate, page, sort, searchQuery, username })
        .then((res) => {
          this.setState({
            messages: [...res.data.contents, ...messages],
            totalPages: res.data.pagination.totalPages,
            currentPage: res.data.pagination.page + 1,
            loading: false,
            noMoreMessages: false,
            totalMessages: res.data.pagination.totalElements
          });
        })
        .catch((e) => this.setState({ loading: false, noMoreMessages: false }));
    } else {
      this.setState({
        noMoreMessages: true
      });
    }
  };

  // handleExport = () => {
  //   const { startDate, endDate, chatRoomRef, username } = this.state;
  //   if (startDate && endDate && chatRoomRef) {
  //     let url = `/api/babble/message/export?chatRoomRef=${chatRoomRef}&fromDate=${startDate.toISOString()}&toDate=${endDate.toISOString()}`;
  //     if (username) {
  //       url += `&username=${username}`;
  //     }

  //     Api.get(url, {
  //       responseType: 'blob'
  //     }).then((response) => {
  //       const href = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = href;
  //       link.setAttribute('download', 'export.csv');
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   } else {
  //     this.setState({
  //       errorMessage: 'Please select chat room and period'
  //     });
  //   }
  // };

  handleOpenAdvancedOptions = () => {
    this.setState((state) => ({ openAdvanced: !state.openAdvanced }));
  };

  handleExportCurrent = async () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useKeysAsHeaders: true,
      filename: `current_messages_babble_${Date.now().toString()}`
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.state.messages);
  };

  handleExportRange = async () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useKeysAsHeaders: true,
      filename: `range_messages_babble_${Date.now().toString()}`
    };
    this.setState({ preparingExportData: true });
    const { startDate, endDate, chatRoomRef, username } = this.state;
    if (startDate && endDate && chatRoomRef) {
      try {
        const maxPageSize = 2000; // 2000 is the max from the backend
        const totalPages = Math.ceil(this.state.totalMessages / maxPageSize);
        let allMessages = [];
        for (let i = 0; i < totalPages; i++) {
          let url = `/api/babble/message?chatRoomRef=${chatRoomRef}&fromDate=${startDate.toISOString()}&toDate=${endDate.toISOString()}&page=${i}&size=${maxPageSize}`;
          if (username) {
            url += `&username=${username}`;
          }
          // eslint-disable-next-line no-await-in-loop
          const response = await Api.chat.rooms(url);
          allMessages = [...response.data.contents, ...allMessages];
        }

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(allMessages);
        this.setState({ preparingExportData: false });
      } catch (e) {
        this.setState({ exportRangeError: 'Some error occured. Please try again later' });
      }
    }
  };

  renderMessages = () => {
    const { messages } = this.state;

    if (messages) {
      if (messages.length) {
        return messages.map((message) => (
          <Comment key={message.ref}>
            <Comment.Content>
              <Comment.Author as="a">{message.createdBy}</Comment.Author>
              <Comment.Metadata>
                <div>{format(message.createdAt, 'DD/MM/YYYY HH:mm')}</div>
              </Comment.Metadata>
              <Comment.Text>{message.value}</Comment.Text>
            </Comment.Content>
          </Comment>
        ));
      } else {
        return <Comment>No messages for selected period</Comment>;
      }
    } else {
      return false;
    }
  };

  render() {
    const { rooms, roomsRefs } = this.props;
    const { exportRangeError, chatRoomRef, startDate, endDate, openAdvanced } = this.state;
    const roomsOptions = roomsRefs.map((roomRef) => ({
      key: roomRef,
      value: roomRef,
      text: rooms[roomRef].name
    }));

    const disableOptions = chatRoomRef === null;

    return (
      <div className="chatHistory">
        <Header as="h3">Chat History</Header>
        <div className="ui form">
          <Form.Group>
            <div className="field">
              <Dropdown
                placeholder="Select room"
                options={roomsOptions}
                value={chatRoomRef}
                onChange={this.handleSelectRoom}
                labeled
                button
                style={{ minWidth: '120px' }}
                className="mini icon"
              />
            </div>
            <div className="field">
              <Input disabled={disableOptions} size="mini">
                <div className="ui label">From</div>
                <DatePicker
                  disabled={disableOptions}
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeStart}
                />
              </Input>
            </div>
            <div className="field">
              <Input disabled={disableOptions} size="mini">
                <div className="ui label">To</div>
                <DatePicker
                  disabled={disableOptions}
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeEnd}
                />
              </Input>
            </div>
            <div>
              <Button
                content="Advanced options"
                icon={openAdvanced ? 'up arrow' : 'down arrow'}
                labelPosition="left"
                size="mini"
                onClick={this.handleOpenAdvancedOptions}
              />
            </div>
          </Form.Group>
          {openAdvanced && (
            <>
              <Header size="tiny">Additional filters</Header>
              <Form.Group>
                <div className="field">
                  <Input
                    disabled={disableOptions}
                    size="mini"
                    labeled
                    label="Search query"
                    onChange={this.handleChangeSearch}
                  />
                </div>
                <div className="field">
                  <Input
                    disabled={disableOptions}
                    size="mini"
                    labeled
                    label="Filter by user"
                    onChange={this.handleChangeUser}
                  />
                </div>

                <Button
                  size="mini"
                  disabled={disableOptions}
                  onClick={this.handleApplyExtraFilters}
                >
                  Apply
                </Button>
              </Form.Group>
            </>
          )}
        </div>
        <Button disabled={disableOptions} size="mini" onClick={this.handleLoadMessages}>
          Force reload
        </Button>
        <Popup
          content="Export all of the messages in the selected date range"
          trigger={
            <Button
              disabled={startDate === null || endDate === null || chatRoomRef === null}
              size="mini"
              onClick={this.handleExportRange}
            >
              {this.state.preparingExportData ? (
                <>
                  <Loader inline active size="mini" />
                  <span>Preparing data, please wait!</span>
                </>
              ) : (
                ' Export range'
              )}
            </Button>
          }
        />
        <Popup
          content="Export all of the messages which are currently loaded."
          trigger={
            <Button
              disabled={this.state.messages.length === 0}
              size="mini"
              onClick={this.handleExportCurrent}
            >
              Export current messages
            </Button>
          }
        />
        <div>{exportRangeError}</div>
        <Card className="chatHistory__messages">
          <div className="status__label">
            <div className="status__label--content">
              {(this.state.chatRoomRef === null && 'Please select a room...') ||
                (this.state.loading && (
                  <div>
                    <Loader size="tiny" active inline />
                    &nbsp;Loading messages ...
                  </div>
                )) ||
                (this.state.noMoreMessages && <div>No more messages...</div>)}
            </div>
          </div>
          <Card.Content>
            <Message onTriggerFetch={this.handleLoadMessages} messages={this.state.messages} />
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rooms: state.Chat.entities,
  roomsRefs: state.Chat.result
});

export default connect(mapStateToProps)(Chat);
