import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress, Message, Icon } from 'semantic-ui-react';

const SECOND = 50;

const Notification = ({ className, children, timeout, onSelect, onRemove }) => {
  const [timer, setTimer] = useState(timeout);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    const intervalId =
      timer > 0 &&
      !pause &&
      setTimeout(() => {
        setTimer((seconds) => seconds - SECOND);
      }, SECOND);

    if (timer < SECOND) {
      onRemove(children);
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [children, timer, pause, onRemove]);

  const handleClick = () => {
    onSelect(children);
  };
  const handleClose = (ev) => {
    ev.stopPropagation();
    onRemove(children);
  };
  const handleMouseEnter = () => {
    setPause(true);
  };
  const handleMouseLeave = () => {
    setPause(false);
  };

  const percent = (timer / timeout) * 100;

  return (
    <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Message onClick={handleClick}>
        <Icon name="close" onClick={handleClose} />
        <span>{children}</span>
        {timeout && <Progress percent={percent} attached="bottom" />}
      </Message>
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.string,
  timeout: PropTypes.number
};

export default Notification;
