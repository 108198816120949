/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Icon, Popup } from 'semantic-ui-react';
import UAParser from 'ua-parser-js';
import { faUserAgent } from 'fontawesome-user-agent';
import './Session.css';

const Session = ({ sessionId, userAgent }) => {
  const content = new UAParser(userAgent).getResult();
  const icons = faUserAgent(userAgent, (options) => {
    options.icons.platform.mobile = {
      name: 'mobile',
      style: 'fas'
    };
    options.icons.platform.tablet = {
      name: 'tablet',
      style: 'fas'
    };
    return options;
  });

  return (
    <div className="session">
      {sessionId ? <span className="session__id">#{sessionId}</span> : null}
      <Popup
        position="bottom center"
        header="Platform"
        trigger={<Icon name={icons.platform.name} />}
        content={
          <>
            Type: {startCase(content.device.type) || 'Desktop'}
            {content.device.vendor && (
              <>
                {' '}
                <br /> Vendor: {content.device.vendor}{' '}
              </>
            )}
            {content.device.vendor && (
              <>
                <br /> Model: {content.device.model}
              </>
            )}
          </>
        }
      />
      <Popup
        position="bottom center"
        header="OS"
        trigger={<Icon name={icons.os.name} />}
        content={
          <>
            Name: {content.os.name}
            <br /> Version: {content.os.version}
          </>
        }
      />
      <Popup
        position="bottom center"
        header="Browser"
        trigger={<Icon name={icons.browser.name} />}
        content={
          <>
            Name: {content.browser.name}
            <br /> Version: {content.browser.version}
          </>
        }
      />
    </div>
  );
};

Session.propTypes = {
  sessionId: PropTypes.string,
  userAgent: PropTypes.string
};

Session.defaultProps = {
  sessionId: null,
  userAgent: null
};

export default Session;
