import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SockJsClient from 'react-stomp';
import { ToastContainer } from 'react-toastify';
import { authActions, chatActions, socketActions, userActions } from 'actions';
import Navbar from '../Navbar';
import SideMenu from '../SideMenu';
import Modals from '../Modals';
import Content from './Content';
import 'react-toastify/dist/ReactToastify.css';
import './Dashboard.css';

const Dashboard = () => {
  const wsSourceUrl = `${process.env.REACT_APP_BASE_URL}/babble-ws`;
  const isMock = process.env.REACT_APP_MOCK_API === 'true';

  const clientRef = useRef();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.Socket.subscriptions);
  const roomsRefs = useSelector((state) => state.Chat.result);
  const username = useSelector((state) => state.User.username);

  useEffect(() => {
    dispatch(userActions.getCurrent());
    dispatch(chatActions.getRooms());
    dispatch(socketActions.addSubscription('/topic/activity/bingo.message'));
  }, [dispatch]);

  const handleToggleSideMenu = () => {
    setSideMenuOpen((x) => !x);
  };

  const handleReceiveMessage = (message) => {
    dispatch(chatActions.handleMessage(message));
  };

  const handleSendMessage = (roomRef, message) => {
    const topic = `/app/${roomRef}/chat.message`;

    try {
      clientRef.current.sendMessage(topic, JSON.stringify(message));
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSignOut = () => {
    dispatch(authActions.resetSession());
  };

  const handleConnected = () => {
    console.log('connected');
  };

  const handleDisconnected = () => {
    console.log('disconnected');
  };

  const setClientRef = (client) => {
    clientRef.current = client;
  };

  const handleConnectFailure = (error) => {
    dispatch(socketActions.connectFailed(error));
  };

  return (
    <div className="dashboard">
      {!isMock && (
        <SockJsClient
          ref={setClientRef}
          url={wsSourceUrl}
          autoReconnect={false}
          onMessage={handleReceiveMessage}
          topics={subscriptions}
          onConnect={handleConnected}
          onDisconnect={handleDisconnected}
          onConnectFailure={handleConnectFailure}
          debug
        />
      )}
      <Modals />
      <ToastContainer position="bottom-left" />
      {roomsRefs ? (
        <SideMenu isOpen={sideMenuOpen}>
          <Navbar
            username={username}
            onToggleSideMenu={handleToggleSideMenu}
            onSignOut={handleSignOut}
          />
          <div className="dashboard__content">
            <Content roomsRefs={roomsRefs} onSendMessage={handleSendMessage} />
          </div>
        </SideMenu>
      ) : null}
    </div>
  );
};

export default Dashboard;
