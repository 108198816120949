import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Card, Dimmer } from 'semantic-ui-react';
import { useAutoScroll } from 'hooks/useAutoScroll';
import { chatActions } from 'actions';
import Message from '../Message';
import TextComposer from '../TextComposer';
import '../Chat/Chat.css';

const Conversation = ({
  roomRef,
  roomName,
  isPrivate,
  messages,
  onSendMessage,
  preloadData,
  connectFailed
}) => {
  const containerRef = useAutoScroll(messages.length);
  const dispatch = useDispatch(null);

  const handleSendMessage = (message) => {
    onSendMessage(roomRef, {
      value: message,
      sentAt: Date.now() / 1000
    });
    return true;
  };

  useEffect(() => {
    if (preloadData) {
      dispatch(
        chatActions.getMessages({
          chatRoomRef: roomRef,
          startDate: new Date(new Date() - 1000 * 60 * 60 * 24 * 2),
          endDate: new Date(),
          searchQuery: null,
          username: null,
          sort: 'createdAt,desc'
        })
      );
    }
  }, [roomRef, preloadData, dispatch]);

  return (
    <Dimmer.Dimmable as={Card} dimmed={connectFailed} blurring className="chat">
      <div className="ui content comments" ref={containerRef}>
        <Message messages={messages} />
      </div>
      <Dimmer active={connectFailed}>{connectFailed}</Dimmer>
      <Card.Content extra>
        <TextComposer
          placeholder={isPrivate ? `Message @${roomRef}` : `Message #${roomName}`}
          onSendMessage={handleSendMessage}
        />
      </Card.Content>
    </Dimmer.Dimmable>
  );
};

Conversation.propTypes = {
  roomRef: PropTypes.string.isRequired,
  roomName: PropTypes.string,
  isPrivate: PropTypes.bool,
  onSendMessage: PropTypes.func.isRequired,
  messages: PropTypes.array,
  preloadData: PropTypes.bool
};

Conversation.defaultProps = {
  messages: [],
  roomName: 'roomName',
  isPrivate: false,
  preloadData: false
};

export default Conversation;
