import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const Error = ({ roomName, roomType, eventType }) => (
  <div>
    SHIT! A {eventType} event was triggered in error for <BingoRoom id={roomName} type={roomType} />
    . CALL someone!
  </div>
);

Error.propTypes = {
  roomName: PropTypes.string,
  roomType: PropTypes.string,
  eventType: PropTypes.string
};

Error.defaultProps = {
  roomName: '',
  roomType: '',
  eventType: ''
};

export default Error;
