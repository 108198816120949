import React, { useEffect } from 'react';
import { startCase } from 'lodash';
import { Button, Modal, Label, Icon, Table, Loader, Dimmer } from 'semantic-ui-react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'actions';
import { selectLoadingType } from 'selectors/loading';
import { selectUsersById } from 'selectors/users';
import Session from '../Session';

const formatGameUrl = (gameRef) => `https://mrq.com/bingo/${gameRef}`;
const isFetched = (user) => user?.currentBalance;

const PlayerInfo = ({ name, username, userRef, visible, closeModal, sessions, createdAt }) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUsersById(userRef));
  const loading = useSelector(selectLoadingType('GET_USER')) || !isFetched(userData);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(usersActions.getUser(userRef));
    };
    if (!isFetched(userData) && userRef) {
      fetchData();
    }
  }, [dispatch, userRef, userData, visible]);

  const handleCloseModal = () => {
    closeModal(name);
  };

  const {
    currentBalance,
    firstDepositDate,
    lastDepositDate,
    hasDeposited,
    totalDeposits,
    latestBingoBetTransaction,
    latestBingoWinTransaction
  } = userData || {};

  return (
    <Modal open={visible} size="small" onClose={handleCloseModal}>
      <Modal.Header>
        <Label size="big">
          <Icon name="user circle" />
          {username}
        </Label>{' '}
        Info
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" />
          </Dimmer>
        ) : (
          <Modal.Description>
            <Table basic="very" celled collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Icon name="money outline" />
                    Balance:
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      <Icon name="pound sign" />
                      {currentBalance.toFixed(2)}
                    </Label>
                  </Table.Cell>
                </Table.Row>

                {hasDeposited && totalDeposits && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="chart line" />
                      Total deposits:
                    </Table.Cell>
                    <Table.Cell>
                      <Label>{totalDeposits}</Label>
                    </Table.Cell>
                  </Table.Row>
                )}
                {hasDeposited && firstDepositDate && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="credit card outline" />
                      First deposit:
                    </Table.Cell>
                    <Table.Cell>
                      <Label>
                        <Icon name="calendar outline" />
                        {format(firstDepositDate, 'DD/MM/YYYY HH:mm')}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                )}
                {hasDeposited && lastDepositDate && totalDeposits > 1 && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="credit card outline" />
                      Last deposit:
                    </Table.Cell>
                    <Table.Cell>
                      <Label>
                        <Icon name="calendar outline" />
                        {format(lastDepositDate, 'DD/MM/YYYY HH:mm')}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                )}
                {latestBingoBetTransaction && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="cart arrow down" />
                      Bingo Latest Bet:
                    </Table.Cell>
                    <Table.Cell>
                      <Label>
                        <Icon name="pound sign" />
                        {latestBingoBetTransaction.amount.toFixed(2)}
                      </Label>{' '}
                      on{' '}
                      <Label
                        as="a"
                        href={formatGameUrl(latestBingoBetTransaction.gameRef)}
                        target="_blank"
                      >
                        <Icon name="game" />
                        {startCase(latestBingoBetTransaction.gameRef)}
                      </Label>{' '}
                      at{' '}
                      <Label>
                        <Icon name="calendar outline" />
                        {format(latestBingoBetTransaction.createdAt, 'DD/MM/YYYY HH:mm')}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                )}
                {latestBingoWinTransaction && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="cart plus" />
                      Bingo Latest Win:
                    </Table.Cell>
                    <Table.Cell>
                      <Label>
                        <Icon name="pound sign" />
                        {latestBingoWinTransaction.amount.toFixed(2)}
                      </Label>{' '}
                      on{' '}
                      <Label
                        as="a"
                        href={formatGameUrl(latestBingoWinTransaction.gameRef)}
                        target="_blank"
                      >
                        <Icon name="game" />
                        {startCase(latestBingoWinTransaction.gameRef)}
                      </Label>{' '}
                      at{' '}
                      <Label>
                        <Icon name="calendar outline" />
                        {format(latestBingoWinTransaction.createdAt, 'DD/MM/YYYY HH:mm')}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                )}
                {sessions && (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="globe" />
                      Sessions:
                    </Table.Cell>
                    <Table.Cell>
                      {sessions.map((session) =>
                        session.userAgentInfo ? (
                          <Label>
                            <Session
                              key={session.sessionId}
                              sessionId={session.sessionId}
                              userAgent={session.userAgentInfo.userAgent}
                            />
                          </Label>
                        ) : null
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    <Icon name="user plus" />
                    Joined in room:
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      <Icon name="calendar outline" />
                      {format(createdAt, 'DD/MM/YYYY HH:mm')}
                    </Label>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Description>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button positive content="Close" onClick={handleCloseModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default PlayerInfo;
