import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const EndGame = ({ bingoRoomName, bingoRoomType }) => (
  <div>
    Sooo! The game just ended on
    <BingoRoom id={bingoRoomName} type={bingoRoomType} />
    and no one won anything.
  </div>
);

EndGame.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string
};

EndGame.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: ''
};

export default EndGame;
