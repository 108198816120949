import React from 'react';
import PropTypes from 'prop-types';
import BingoRoom from './BingoRoom';

const RoomFavourite = ({ bingoRoomName, bingoRoomType, favourited, username }) => {
  if (favourited) {
    return (
      <div>
        Yes! <b>{username}</b> just added <BingoRoom id={bingoRoomName} type={bingoRoomType} />
        to his/her favourite list.
      </div>
    );
  } else {
    return (
      <div>
        Bonkers! <b>{username}</b> just removed{' '}
        <BingoRoom id={bingoRoomName} type={bingoRoomType} />
        from his/her favourite list.
      </div>
    );
  }
};

RoomFavourite.propTypes = {
  bingoRoomName: PropTypes.string,
  bingoRoomType: PropTypes.string,
  username: PropTypes.string,
  favourited: PropTypes.bool
};

RoomFavourite.defaultProps = {
  bingoRoomName: '',
  bingoRoomType: '',
  username: '[Unknown user]',
  favourited: false
};

export default RoomFavourite;
