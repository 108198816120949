import update from 'update-immutable';

const INITIAL_STATE = {
  types: []
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  const actionType = action.type?.replace('_PENDING', '');

  if (action.type.endsWith('_PENDING')) {
    return update(state, {
      types: { $push: [actionType] }
    });
  }
  if (action.type.endsWith('_FULFILLED') || action.type.endsWith('_REJECTED')) {
    return update(state, {
      types: { $splice: [[actionType.indexOf(state.types), 1]] }
    });
  }
  return state;
};

export default loadingReducer;
