import React, { useState } from 'react';
import { Button, Form, Label, Modal, Select, TextArea, Icon } from 'semantic-ui-react';
import { addMinutes } from 'date-fns';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadingType } from 'selectors/loading';
import Api from '../../services/Api';

const periodOptions = [
  { key: '10', value: '10', text: '10 minutes' },
  { key: '30', value: '30', text: '30 minutes' },
  { key: '60', value: '60', text: '1 hour' },
  { key: '360', value: '360', text: '6 hours' },
  { key: '1440', value: '1440', text: '24 hours' }
];

const blockOptions = [
  { key: 'kick', value: 'kick', text: 'Kick' },
  { key: 'ban', value: 'ban', text: 'Ban' }
];

const defaultValue = (options) => options && options[0]?.value;

const BlockPlayer = ({ roomRef, userRef, visible, closeModal, name }) => {
  const [action, setAction] = useState(defaultValue(blockOptions));
  const [period, setPeriod] = useState(defaultValue(periodOptions));
  const [reason, setReason] = useState();
  const [errorMessage, setError] = useState();

  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingType('BLOCK_USER'));

  const handleChangeAction = (ev, { value }) => {
    setAction(value);
  };

  const handleChangePeriod = (ev, { value }) => {
    setPeriod(value);
  };

  const handleChangeReason = (ev, { value }) => {
    setReason(value);
    setError();
  };

  const handleSubmit = async () => {
    if (reason) {
      try {
        const user = {
          username: userRef,
          chatRoomRef: roomRef,
          reason: reason,
          untilDate: Math.floor(addMinutes(new Date(), period).getTime() / 1000)
        };
        const blockUser = action === 'kick' ? Api.chat.kick : Api.chat.ban;
        await dispatch({ type: 'BLOCK_USER', payload: blockUser(user) });
        closeModal(name);
      } catch (error) {
        setError(error.toString());
      }
    } else {
      setError(`Please enter a reason to ${action} ${userRef}`);
    }
  };

  const handleCloseModal = () => {
    closeModal(name);
  };

  return (
    <Modal open={visible} size="tiny" onClose={handleCloseModal}>
      <Modal.Header>
        {startCase(action)}{' '}
        <Label size="big">
          <Icon name="user circle" />
          {userRef}
        </Label>{' '}
        from{' '}
        <Label size="big">
          <Icon name="group" />
          {roomRef}
        </Label>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form error={errorMessage}>
            <Form.Group widths="equal">
              <Form.Field
                control={Select}
                label="Kick / Ban"
                options={blockOptions}
                onChange={handleChangeAction}
                value={action}
                defaultValue={action}
              />
              <Form.Field
                control={Select}
                label="Period"
                placeholder="Select"
                options={periodOptions}
                onChange={handleChangePeriod}
                value={period}
                defaultValue={period}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                width={16}
                control={TextArea}
                fluid
                label="Reason"
                autoHeight
                onChange={handleChangeReason}
                value={reason}
                error={errorMessage}
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Close" onClick={handleCloseModal} />
        <Button
          loading={loading}
          disabled={loading}
          positive
          content="Submit"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BlockPlayer;
