import Api from 'services/Api';

const getRooms = () => ({
  type: 'GET_ROOMS',
  payload: Api.chat.rooms()
});

const getUsersForRoom = (chatRoomRef) => ({
  type: 'GET_USERS',
  payload: Api.chat.users({ chatRoomRef })
});

const getMessages = (queryParams) => ({
  type: 'GET_MESSAGES',
  payload: Api.chat.messages(queryParams)
});

const chatEvents = ['MESSAGE', 'SUBSCRIBE_USER', 'UNSUBSCRIBE_USER'];

const bingoEvents = [
  'FAVOURITE_ROOM',
  'UNFAVOURITE_ROOM',
  'TICKETS_QUICK_BUY',
  'TICKETS_MANUAL_BUY',
  'TICKETS_REFUND',
  'START_GAME',
  'RESET_GAME',
  'CANCEL_GAME',
  'END_GAME',
  'BINGO_PROGRESSIVE_WINNER',
  'BINGO_GAME_JACKPOT_WINNER',
  'ONE_LINE_WINNER',
  'TWO_LINES_WINNER'
];

const handleMessage = (message) => {
  if (chatEvents.includes(message.type)) {
    return {
      type: message.type,
      payload: message.payload
    };
  }

  if (bingoEvents.includes(message.event)) {
    return {
      type: message.event,
      payload: message
    };
  }

  return null;
};

const startPrivateRoom = (room) => ({
  type: 'START_PRIVATE_ROOM',
  payload: room
});

const closePrivateRoom = (roomRef) => ({
  type: 'CLOSE_PRIVATE_ROOM',
  payload: roomRef
});

export const chatActions = {
  getMessages,
  getRooms,
  getUsersForRoom,
  handleMessage,
  startPrivateRoom,
  closePrivateRoom
};
