import { combineReducers } from 'redux';
import auth from './auth';
import bingo from './bingo';
import chat from './chat';
import modals from './modals';
import loading from './loading';
import socket from './socket';
import user from './user';
import users from './users';

const appReducer = combineReducers({
  Auth: auth,
  Bingo: bingo,
  Chat: chat,
  Modals: modals,
  Loading: loading,
  Socket: socket,
  User: user,
  Users: users
});

const rootReducer = (state, action) =>
  action.type === 'Root/RESET'
    ? appReducer(
        {
          ...state,
          Bingo: undefined,
          Chat: undefined,
          Modals: undefined,
          Loading: undefined,
          Socket: undefined,
          User: undefined,
          Users: undefined
        },
        action
      )
    : appReducer(state, action);

export default rootReducer;
