import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const UserLabel = ({ text }) => (
  <Label color="red" horizontal className="user__label">
    {text}
  </Label>
);

UserLabel.propTypes = {
  text: PropTypes.string.isRequired
};

export default UserLabel;
