import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Comment } from 'semantic-ui-react';
import User from '../User';

const Message = ({ author, time, content, sentByModerator }) => (
  <Comment>
    <Comment.Content>
      <Comment.Author as="a">
        <User username={author} isModerator={sentByModerator} />
      </Comment.Author>
      <Comment.Metadata>
        <div>{format(time, 'DD/MM/YYYY HH:mm')}</div>
      </Comment.Metadata>
      <Comment.Text>{content}</Comment.Text>
    </Comment.Content>
  </Comment>
);

Message.propTypes = {
  author: PropTypes.string,
  time: PropTypes.string,
  content: PropTypes.string
};

Message.defaultProps = {
  author: '',
  time: '',
  content: ''
};

export default Message;
